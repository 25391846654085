import { Link } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';
import BreadcrumbsSchema from 'components/schemas/BreadcrumbsSchema';

export type Item = {
  label: string,
  url?: string,
};

type ItemWithoutUrl = Omit<Item, 'url'> & { url?: never };

export interface Props {
  items: [...Item[], ItemWithoutUrl],
  hideHomeItem?: boolean,
}

const Breadcrumbs:React.FC<Props> = ({ items, hideHomeItem = false }) => {
  if (!items.length) return null;

  const homeItem: Item = {
    label: 'Home',
    url: '/',
  };

  const itemsToRender: Props['items'] = [
    ...(!hideHomeItem ? [homeItem] : []),
    ...items,
  ];

  return (
    <BreadcrumbsOuter>
      <BreadcrumbsSchema breadcrumbItems={itemsToRender} />
      {itemsToRender.map(({ url, label }) => {
        const item = url
          ? <ClickableItem to={url}>{label}</ClickableItem>
          : <NonClickableItem>{label}</NonClickableItem>;

        return <ItemWrap key={`${url || ''}${label}`}>{item}</ItemWrap>;
      })}
    </BreadcrumbsOuter>
  );
};
export default Breadcrumbs;

const BreadcrumbsOuter = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 1.2rem;
  line-height: 1.5em;
`;
const ItemWrap = styled.div`
  display: flex;
  &:after {
    content: '/';
    display: inline-block;
    padding: 0 0.4em;
    color: ${(p) => p.theme.palette.text};
  }
  &:last-of-type {
    &:after {
      content: none;
    }
    font-weight: bold;
  }
`;
const itemCss = css`
  display: block;
  text-decoration: none;
  font-weight: normal;
`;
const ClickableItem = styled(Link)`
  ${itemCss};
  color: ${(p) => p.theme.palette.interactive};
`;
const NonClickableItem = styled.div`
  ${itemCss};
`;
