import React, { PropsWithChildren } from 'react';
// import styled from 'styled-components';

interface Props<SchemaType> {
  schema: SchemaType,
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const SchemaBase = <SchemaType, >(
  props: PropsWithChildren<Props<SchemaType>>,
): JSX.Element => {
  const { schema } = props;
  const schemaWithContext = {
    '@context': 'https://schema.org/',
    ...schema,
  };
  const schemaHtml = JSON.stringify(schemaWithContext);
  // eslint-disable-next-line react/no-danger
  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schemaHtml }} />;
};
export default SchemaBase;
