import React from 'react';
import SEO from 'lib/components/Seo';
import Block from 'src/components/Block';
import PageHeader from 'components/PageHeader';
import Breadcrumbs from 'src/components/Breadcrumbs';
import styled from 'styled-components';
import Container from 'src/components/Container';

interface Props {}

const TestPage:React.FC<Props> = () => {
  return (
    <TestPageOuter>
      <SEO
        title="test"
        description="test"
      />
      <PageHeader h1="Test" />
      <Container>
        <Breadcrumbs items={[{ label: 'Test' }]} />
        <Block>
          This is test page
        </Block>
      </Container>
    </TestPageOuter>
  );
};
export default TestPage;

const TestPageOuter = styled.div``;
