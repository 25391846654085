import React from 'react';
import { BreadcrumbList } from 'schema-dts';
import SchemaBase from 'lib/components/schemas/SchemaBase';
import { Item } from 'components/Breadcrumbs';


interface Props {
  breadcrumbItems: Item[],
}


const BreadcrumbsSchema:React.FC<Props> = ({ breadcrumbItems }) => {
  const schema: BreadcrumbList = {
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbItems.map(({ label, url }, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      name: label,
      item: url && `${process.env.GATSBY_SITE_URL || ''}${url}`,
    })),
  };
  return <SchemaBase schema={schema} />;
};
export default BreadcrumbsSchema;
